
import {
  defineComponent,
  onMounted,
  PropType,
  nextTick,
  watchEffect,
  Events,
} from "vue";

import PhotoSwipe from "photoswipe";
import defaultUI from "photoswipe/dist/photoswipe-ui-default";
import { Options, Item } from "photoswipe/dist/photoswipe-ui-default";
export interface ImgItem extends Item {
  src: string;
  w?: number;
  h?: number;
}

export default defineComponent({
  name: "Preview",
  props: {
    imgList: {
      type: Array as PropType<ImgItem[]>,
      default: [],
    },
    currentIndex: {
      type: Number,
      default: 0,
    },
  },
  emits: ["close"],
  component: {},
  setup(props, ctx) {
    let gallery: PhotoSwipe<Options> | null = null;

    function getSrcSize(src: string) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;

        let timer = 0;
        timer = setInterval(() => {
          if (img.width > 0) {
            clearInterval(timer);

            resolve({
              src: src,
              w: img.width,
              h: img.height,
            });
          }
        }, 40);
        img.addEventListener("error", (error) => {
          clearInterval(timer);
          resolve({
            src: src,
            w: 0,
            h: 0,
          });
        });
      });
    }
    function getSrcInfo(items: ImgItem[]) {
      return Promise.all(
        items.map((item) => {
          return getSrcSize(item.src);
        })
      );
    }

    async function init() {
      const pswpElement: HTMLElement | null = document.querySelector(".pswp");

      // build items array
      const arr = await getSrcInfo(props.imgList);

      const items = arr.filter((item) => (item as any).w > 0);
      if (!items.length) {
        ctx.emit("close");
        return false;
      }
      // define options (if needed)
      const options = {
        // optionName: 'option value'
        tapToClose: true,
        closeEl: false,
        // for example:
        index: props.currentIndex, // start at first slide
        fullscreenEl: false,
        closeOnVerticalDrag: true,
        // showAnimationDuration: 0,
        // hideAnimationDuration: 300,
        // shareButtons: [
        //     { id: 'wechat', label: '分享微信', url: '#' },
        //     { id: 'weibo', label: '新浪微博', url: '#' },
        //     { id: 'download', label: '保存图片', url: '{{raw_image_url}}', download: true }
        // ],
        // isClickableElement: function (el: HTMLElement) {
        //   console.log(el.tagName)
        //   if (gallery) {
        //     gallery.close();

        //     ctx.emit("close" , 0);
        //   }
        //   return true;
        // },
      };
      // Initializes and opens PhotoSwipe
      nextTick(() => {
        console.log(11111);

        gallery = new PhotoSwipe(
          pswpElement as HTMLElement,
          defaultUI,
          items as Item[],
          options
        );
        gallery.init();
        gallery.listen("close", function () {
          ctx.emit("close");
        });
      });
    }
    onMounted(() => {
      init();
    });
  },
});
